/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createIncome = /* GraphQL */ `
  mutation CreateIncome(
    $input: CreateIncomeInput!
    $condition: ModelIncomeConditionInput
  ) {
    createIncome(input: $input, condition: $condition) {
      id
      frequency
      description
      startPaymentDate
      status
      amount
      bankAccountID
      bankAccount {
        id
        bank
        bsb
        accountnumber
        description
        status
        openingBalance
        currentBalance
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateIncome = /* GraphQL */ `
  mutation UpdateIncome(
    $input: UpdateIncomeInput!
    $condition: ModelIncomeConditionInput
  ) {
    updateIncome(input: $input, condition: $condition) {
      id
      frequency
      description
      startPaymentDate
      status
      amount
      bankAccountID
      bankAccount {
        id
        bank
        bsb
        accountnumber
        description
        status
        openingBalance
        currentBalance
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteIncome = /* GraphQL */ `
  mutation DeleteIncome(
    $input: DeleteIncomeInput!
    $condition: ModelIncomeConditionInput
  ) {
    deleteIncome(input: $input, condition: $condition) {
      id
      frequency
      description
      startPaymentDate
      status
      amount
      bankAccountID
      bankAccount {
        id
        bank
        bsb
        accountnumber
        description
        status
        openingBalance
        currentBalance
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createExpense = /* GraphQL */ `
  mutation CreateExpense(
    $input: CreateExpenseInput!
    $condition: ModelExpenseConditionInput
  ) {
    createExpense(input: $input, condition: $condition) {
      id
      frequency
      description
      startPaymentDate
      status
      amount
      bankAccountID
      bankAccount {
        id
        bank
        bsb
        accountnumber
        description
        status
        openingBalance
        currentBalance
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateExpense = /* GraphQL */ `
  mutation UpdateExpense(
    $input: UpdateExpenseInput!
    $condition: ModelExpenseConditionInput
  ) {
    updateExpense(input: $input, condition: $condition) {
      id
      frequency
      description
      startPaymentDate
      status
      amount
      bankAccountID
      bankAccount {
        id
        bank
        bsb
        accountnumber
        description
        status
        openingBalance
        currentBalance
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteExpense = /* GraphQL */ `
  mutation DeleteExpense(
    $input: DeleteExpenseInput!
    $condition: ModelExpenseConditionInput
  ) {
    deleteExpense(input: $input, condition: $condition) {
      id
      frequency
      description
      startPaymentDate
      status
      amount
      bankAccountID
      bankAccount {
        id
        bank
        bsb
        accountnumber
        description
        status
        openingBalance
        currentBalance
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createBankAccount = /* GraphQL */ `
  mutation CreateBankAccount(
    $input: CreateBankAccountInput!
    $condition: ModelBankAccountConditionInput
  ) {
    createBankAccount(input: $input, condition: $condition) {
      id
      bank
      bsb
      accountnumber
      description
      status
      openingBalance
      currentBalance
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateBankAccount = /* GraphQL */ `
  mutation UpdateBankAccount(
    $input: UpdateBankAccountInput!
    $condition: ModelBankAccountConditionInput
  ) {
    updateBankAccount(input: $input, condition: $condition) {
      id
      bank
      bsb
      accountnumber
      description
      status
      openingBalance
      currentBalance
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteBankAccount = /* GraphQL */ `
  mutation DeleteBankAccount(
    $input: DeleteBankAccountInput!
    $condition: ModelBankAccountConditionInput
  ) {
    deleteBankAccount(input: $input, condition: $condition) {
      id
      bank
      bsb
      accountnumber
      description
      status
      openingBalance
      currentBalance
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      description
      transactionDate
      amount
      bankAccountID
      bankAccount {
        id
        bank
        bsb
        accountnumber
        description
        status
        openingBalance
        currentBalance
        createdAt
        updatedAt
        owner
        __typename
      }
      incomeID
      income {
        id
        frequency
        description
        startPaymentDate
        status
        amount
        bankAccountID
        createdAt
        updatedAt
        owner
        __typename
      }
      expenseID
      expense {
        id
        frequency
        description
        startPaymentDate
        status
        amount
        bankAccountID
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      description
      transactionDate
      amount
      bankAccountID
      bankAccount {
        id
        bank
        bsb
        accountnumber
        description
        status
        openingBalance
        currentBalance
        createdAt
        updatedAt
        owner
        __typename
      }
      incomeID
      income {
        id
        frequency
        description
        startPaymentDate
        status
        amount
        bankAccountID
        createdAt
        updatedAt
        owner
        __typename
      }
      expenseID
      expense {
        id
        frequency
        description
        startPaymentDate
        status
        amount
        bankAccountID
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      description
      transactionDate
      amount
      bankAccountID
      bankAccount {
        id
        bank
        bsb
        accountnumber
        description
        status
        openingBalance
        currentBalance
        createdAt
        updatedAt
        owner
        __typename
      }
      incomeID
      income {
        id
        frequency
        description
        startPaymentDate
        status
        amount
        bankAccountID
        createdAt
        updatedAt
        owner
        __typename
      }
      expenseID
      expense {
        id
        frequency
        description
        startPaymentDate
        status
        amount
        bankAccountID
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
