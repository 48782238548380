/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getIncome = /* GraphQL */ `
  query GetIncome($id: ID!) {
    getIncome(id: $id) {
      id
      frequency
      description
      startPaymentDate
      status
      amount
      bankAccountID
      bankAccount {
        id
        bank
        bsb
        accountnumber
        description
        status
        openingBalance
        currentBalance
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listIncomes = /* GraphQL */ `
  query ListIncomes(
    $filter: ModelIncomeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIncomes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        frequency
        description
        startPaymentDate
        status
        amount
        bankAccountID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getExpense = /* GraphQL */ `
  query GetExpense($id: ID!) {
    getExpense(id: $id) {
      id
      frequency
      description
      startPaymentDate
      status
      amount
      bankAccountID
      bankAccount {
        id
        bank
        bsb
        accountnumber
        description
        status
        openingBalance
        currentBalance
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listExpenses = /* GraphQL */ `
  query ListExpenses(
    $filter: ModelExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        frequency
        description
        startPaymentDate
        status
        amount
        bankAccountID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBankAccount = /* GraphQL */ `
  query GetBankAccount($id: ID!) {
    getBankAccount(id: $id) {
      id
      bank
      bsb
      accountnumber
      description
      status
      openingBalance
      currentBalance
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listBankAccounts = /* GraphQL */ `
  query ListBankAccounts(
    $filter: ModelBankAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBankAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bank
        bsb
        accountnumber
        description
        status
        openingBalance
        currentBalance
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      description
      transactionDate
      amount
      bankAccountID
      bankAccount {
        id
        bank
        bsb
        accountnumber
        description
        status
        openingBalance
        currentBalance
        createdAt
        updatedAt
        owner
        __typename
      }
      incomeID
      income {
        id
        frequency
        description
        startPaymentDate
        status
        amount
        bankAccountID
        createdAt
        updatedAt
        owner
        __typename
      }
      expenseID
      expense {
        id
        frequency
        description
        startPaymentDate
        status
        amount
        bankAccountID
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        transactionDate
        amount
        bankAccountID
        incomeID
        expenseID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
